import {
  faFacebook,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import { default as Logo } from "./../public/images/logo.png";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              {/* <Link href="#" className="mb-6 inline-block">
                <Image
                  width={130}
                  height={28}
                  src={Logo}
                  className="max-h-7 dark:hidden"
                  alt="Xhibiter | NFT Marketplace"
                />
              </Link> */}

              <Link href="/" className=" mb-6 inline-block">
                <Image
                  height={60}
                  width={60}
                  src={Logo}
                  alt="Xhibiter | NFT Marketplace"
                />
              </Link>
              <p className="dark:text-jacarta-300 mb-12">
                Smart Utilities and Cross-Border Remittance Solutions for the
                21st Century
              </p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text, icon } = item;
                  return (
                    <Link
                      href={href}
                      key={id}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer text-lg hover:text-blue transition-all duration-300 hover:scale-110"
                    >
                      <FontAwesomeIcon
                        icon={
                          text === "facebook"
                            ? faFacebook
                            : text === "twitter"
                            ? faTwitter
                            : text === "telegram"
                            ? faTelegram
                            : ""
                        }
                      />
                    </Link>
                  );
                })}
              </div>
            </div>
            {footerMenuList.map((single) => (
              <div
                className={`md:mx-auto col-span-full sm:col-span-6 md:col-span-4 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text, handler } = item;
                    // return (
                    //   <li key={id}>
                    //     {handler ? (
                    //       <button
                    //         onClick={useWalletHandler()}
                    //         className="hover:text-accent dark:hover:text-white"
                    //       >
                    //         {text}
                    //       </button>
                    //     ) : (
                    //       <Link
                    //         href={href}
                    //         className={`${
                    //           text === "Connect Wallet" && "hidden"
                    //         }hover:text-accent dark:hover:text-white`}
                    //       >
                    //         {text}
                    //       </Link>
                    //     )}
                    //   </li>
                    // );
                    return (
                      <li key={id}>
                        <Link
                          href={href}
                          className={`${
                            text === "Connect Wallet" && "hidden"
                          }hover:text-accent dark:hover:text-white`}
                        >
                          {text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} - NAMX</span>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link
                  href="/tarms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Terms and conditions
                </Link>
              </li>
              <li>
                <Link
                  href="/tarms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Privacy policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
