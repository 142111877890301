import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Analytics } from '@vercel/analytics/react';
import Footer from "./footer";
import Header01 from "./header/Header01";
config.autoAddCss = false;

export default function Layout({ children }) {
  return (
    <>
      <Header01 />
      <main>{children}</main>
      <Analytics />
      <Footer />
    </>
  );
}
