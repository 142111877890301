const footerMenuList = [
  {
    id: 1,
    title: "Company",
    diffClass: "",
    list: [
      {
        id: 2,
        href: "/about",
        text: "About",
      },
      {
        id: 3,
        href: "/contact",
        text: "Contact Us",
      },
      {
        id: 4,
        href: "/news",
        text: "Our Blog",
      },
    ],
  },
  {
    id: 2,
    title: "My Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Connect Wallet",
        handler: "useWalletHandler",
      },
      {
        id: 2,
        href: "#",
        text: "Log In",
      },
      {
        id: 3,
        href: "#",
        text: "Register",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/NAMXCOIN",
    text: "facebook",
    icon: "faFacebook",
  },
  {
    id: 2,
    href: "https://twitter.com/NAMXCOIN",
    text: "twitter",
    icon: "faTwitter",
  },

  {
    id: 3,
    href: "https://web.telegram.org/k/#@namxcoin",
    text: "telegram",
    icon: "faTelegram",
  },
];

export { footerMenuList, socialIcons };
