import { useTheme } from "next-themes";
import { useEffect } from "react";

const SetThemeProvider = ({ children }) => {
  const { setTheme, resolvedTheme } = useTheme();

  useEffect(() => {
    if (resolvedTheme === "light" || resolvedTheme === "system") {
      setTheme("dark");
    }
  }, [resolvedTheme, setTheme]);

  return children;
};

export default SetThemeProvider;
