import { MetaMaskProvider } from "metamask-react";
import { ThemeProvider } from "next-themes";
import { useRouter } from "next/router";
import { useRef } from "react";
import { Provider } from "react-redux";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import Layout from "../components/layout";
import SetThemeProvider from "../components/theme/SetThemeProvider";
import { store } from "../redux/store";
import "../styles/globals.css";

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Meta title="Home" />

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <SetThemeProvider>
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === "/login" ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </UserContext.Provider>
            </MetaMaskProvider>
          </SetThemeProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
